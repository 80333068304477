/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { isEmpty } from "lodash";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Checkbox,
  Hidden,
  Typography,
} from "@mui/material";
import {
  Error,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
  Check,
} from "@mui/icons-material";
import TaskIcon from "@mui/icons-material/Task";

import BottomDrawer from "./bottmDrawer";
import { arrangedLineitems, HtmlTooltip } from "../services/common";
import { autoCap } from "../services/validationService";
import BusinessAccountsList from "./businessAccountsList";
import PermissionDropdown from "./dropdown";
import ViewDocument from "./viewDocument";
import MuiModal from "./muiModel";
import PaginationButtons from "./paginationButtons";
import { useToast } from "../toast/toastContext";
import { canvasDetails } from "../constants/appConstants";
import { Colors } from "../config/default";
import { formatLineItems } from "../services/common";
import { matchPO } from "../services/services";
import { userRoles } from "../constants/appConstants";
import AccountsInput from "./accountsInput";
import "../image.css";

function Reviewbar({
  buttonColors,
  array,
  setIndex,
  index,
  images,
  setButtonColors,
  content,
  lineItem,
  setLineItemsData,
  lineItemsData,
  setIsPoMatched,
  setIsAmountMatched,
  setIsLineItemsMatched,
  totalAmount,
  po,
  isLoading,
  actualValues,
  id,
  imageDimensions,
  getRef,
  setActualValues,
  vendors,
  setRef,
  setTotalAmount,
  setPo,
  setVendors,
  vendorsData,
  submitData,
  userRole,
  annotationType,
  userDetails,
  isPoMatched,
  isAmountMatched,
  permission,
  setPermission,
  glInfo,
  glAccount,
  setGlAccount,
  manualEmails,
  isConstructionCompany,
  jobId,
  setJobId,
  costId,
  setCostId,
  costType,
  setCostType,
  isLineItemsMatched,
  setSelectedEmails,
  selectedEmails,
  userOrVendor,
  updateVendorData,
  submitLoading,
  handleClick,
}) {
  const {
    APPROVE,
    DOC_TYPE,
    GL_ACCOUNT,
    JOB_ID,
    COST_ID,
    COST_TYPE,
    VERIFY,
    MATCH_PO,
  } = canvasDetails;
  const { OWNER, ACCOUNTANT } = userRoles;
  const { showToast } = useToast();
  const [open, setOpen] = useState(false);
  const [typeCheck, setTypeCheck] = useState(false);
  const [jobCheck, setJobCheck] = useState(false);
  const [costCheck, setCostCheck] = useState(false);
  const [glCheck, setGlCheck] = useState(false);
  const [costTypeCheck, setCostTypeCheck] = useState(false);
  const something = buttonColors.filter((item) => item === true);
  const isTextNotChecked =
    array?.length !== buttonColors?.length ||
    something?.length !== array?.length;
  const slideRight = () => {
    setIndex((index + 1) % images?.length);
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images?.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };
  const handleTextClick = (index) => {
    array[index] = !array[index];
    const newButtonColors = [...buttonColors];
    newButtonColors[index] = !buttonColors[index];
    setButtonColors(newButtonColors);
  };
  const handleParentCheckboxChange = () => {
    const allChildSelected =
      buttonColors?.length === content?.length &&
      typeCheck &&
      glCheck &&
      jobCheck &&
      costCheck &&
      costTypeCheck;
    if (
      allChildSelected ||
      buttonColors?.length > 0 ||
      typeCheck ||
      glCheck ||
      jobCheck ||
      costCheck ||
      costTypeCheck
    ) {
      setButtonColors([]);
      setTypeCheck(false);
      setGlCheck(false);
      setCostTypeCheck(false);
      setJobCheck(false);
      setCostCheck(false);
    } else {
      setButtonColors(content.map((_, index) => !buttonColors[index]));
      setTypeCheck(true);
      setGlCheck(true);
      setCostTypeCheck(true);
      setJobCheck(true);
      setCostCheck(true);
    }
  };
  const checkPoMatching = async () => {
    let lineItemsCopy = [...lineItem];
    Object.keys(lineItemsData)?.forEach((key) => {
      lineItemsData[key]?.forEach((val, index) => {
        const check = lineItemsCopy[index]?.findIndex(
          (item) => item?.name === key
        );
        if (check !== -1) {
          lineItemsCopy[index][check].value = val === "N/A" ? "" : val;
        }
      });
    });
    const params = {
      poNumber: po,
      total: totalAmount,
      lineItems: formatLineItems(lineItemsCopy),
    };
    const result = await matchPO(id, params);
    if (result?.status === 200) {
      setIsPoMatched(result?.data?.payload?.data?.poNumber);
      setIsAmountMatched(result?.data?.payload?.data?.total);
      setIsLineItemsMatched(result?.data?.payload?.data?.lineItem);
    } else {
      showToast(result?.data?.metadata?.message, "error");
      setIsPoMatched(result?.data?.payload?.data?.poNumber);
      setIsAmountMatched(result?.data?.payload?.data?.total);
      setIsLineItemsMatched(result?.data?.payload?.data?.lineItem);
    }
  };

  const handleValueChange = (value, index) => {
    let updatedArray = [...actualValues];
    updatedArray[index] = value;
    setActualValues(updatedArray);
  };

  return (
    <div className="left-area" style={{ position: "fixed" }}>
      {isLoading ? (
        <div className="loader" style={{ display: "flex", marginTop: "10rem" }}>
          <CircularProgress size={75} sx={{ color: Colors.DARKBLUE }} />
        </div>
      ) : (
        <Grid
          className="review-bar"
          id="review-bar"
          sx={{
            "&::-webkit-scrollbar": {
              width: "0.2em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              outline: "1px solid slategrey",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#fff",
              outline: "1px solid slategrey",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "3rem",
            }}
          >
            <p className="details-heading">Document Details</p>
            {images?.length > 1 && (
              <Grid
                container
                sx={{
                  height: "max-content",
                }}
              >
                <PaginationButtons
                  disableFirstButton={index === 0}
                  disableSecondButton={index === images.length - 1}
                  slideLeft={slideLeft}
                  slideRight={slideRight}
                  index={index}
                  images={images}
                />
              </Grid>
            )}
          </div>
          {content.map((value, idx) => {
            const isShowDocumentButton = idx === 0;
            const isVendorOrContractor =
              (value.name === "vendor" || value.name === "from_contractor") &&
              vendorsData?.document?.matchVendor !== "N/A" &&
              !isEmpty(vendorsData?.document?.topMatchVendor);

            return (
              <React.Fragment key={idx}>
                {isShowDocumentButton && (
                  <Hidden smUp>
                    <Button
                      variant="contained"
                      disabled={isEmpty(images)}
                      style={{
                        position: "absolute",
                        top: "8em",
                        right: "20px",
                        color: Colors.WHITE,
                        fontSize: "10px",
                        borderRadius: "10px",
                        background: Colors.DARKBLUE,
                        textTransform: "none",
                        "&:hover": {
                          background: Colors.DARKBLUE,
                        },
                      }}
                      onClick={() => setOpen(true)}
                    >
                      Show Document
                    </Button>
                    <ViewDocument
                      selectedImage={images}
                      setOpen={setOpen}
                      open={open}
                    />
                  </Hidden>
                )}
                <div
                  className="value-button"
                  ref={setRef("button_" + value.name)}
                  key={idx}
                  onMouseEnter={() => handleClick(value.name, idx, content)}
                  // onClick={() => handleClick(value.name, idx, content)}
                  // onMouseLeave={() => handleClick("", idx, content)}
                >
                  <Box className="box-style">
                    <p
                      style={{
                        marginLeft: "15px",
                        color: Colors.TEXTGREY,
                        display: "inline-flex",
                      }}
                    >
                      <Check
                        sx={{
                          fontSize: "1.5em",
                          marginRight: ".5rem",
                          color:
                            buttonColors[idx] === true ? Colors.DARKBLUE : "",
                        }}
                        onClick={() => handleTextClick(idx, "success")}
                      />
                      {autoCap(value.name)}
                    </p>
                  </Box>
                  {isShowDocumentButton ? (
                    <>
                      {isVendorOrContractor ? (
                        <div className="first-input">
                          <BusinessAccountsList
                            permission={vendors}
                            setPermission={setVendors}
                            question=""
                            data={vendorsData?.document?.topMatchVendor}
                            color={Colors.TEXTGREY}
                          />
                        </div>
                      ) : (
                        <input
                          className="input-field-style"
                          value={actualValues[idx]}
                          onChange={(e) => {
                            handleValueChange(e.target.value, idx);
                            setVendors(e.target.value);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <div className="input-wrapper">
                      <input
                        className="input-field-style"
                        value={actualValues[idx]?.replace(/[$%]/g, "")}
                        onChange={(e) => {
                          handleValueChange(e.target.value, idx);
                          value?.name === "purchase_order"
                            ? setPo(e.target.value)
                            : value?.name === "total"
                            ? setTotalAmount(e.target.value)
                            : "";
                        }}
                      />
                      {(value.name === "purchase_order" &&
                        isPoMatched === false &&
                        userDetails?.user?.poMatchPermission === true) ||
                      (value.name === "total" &&
                        userDetails?.user?.poMatchPermission &&
                        isAmountMatched === false) ? (
                        <HtmlTooltip
                          title={
                            <Typography
                              color="inherit"
                              sx={{ fontSize: "1em" }}
                            >
                              {value.name === "purchase_order"
                                ? "PO does not match"
                                : "Total does not match"}
                            </Typography>
                          }
                        >
                          <Error
                            sx={{
                              color: Colors.RED,
                            }}
                          />
                        </HtmlTooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </React.Fragment>
            );
          })}
          {userOrVendor === "app" && (
            <>
              {userRole === ACCOUNTANT || userRole === OWNER ? (
                <>
                  <div className="value-button">
                    <Box className="box-style">
                      <p className="paragraph-style">
                        <Check
                          sx={{
                            fontSize: "1.5em",
                            marginRight: ".5rem",
                            color: typeCheck === true ? Colors.DARKBLUE : "",
                          }}
                          onClick={() => setTypeCheck(!typeCheck)}
                        />
                        {DOC_TYPE}
                      </p>
                    </Box>
                    <div className="docType-wrapper">
                      <PermissionDropdown
                        permission={permission}
                        setPermission={setPermission}
                        question=""
                        option1="Invoice"
                        option2="G702"
                        option3="Receipt"
                        color={Colors.TEXTGREY}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="value-button">
                  <Box className="box-style">
                    <p className="paragraph-style">
                      <Check
                        sx={{
                          fontSize: "1.5em",
                          marginRight: ".5rem",
                          color: typeCheck === true ? Colors.DARKBLUE : "",
                        }}
                        onClick={() => setTypeCheck(!typeCheck)}
                      />
                      {DOC_TYPE}
                    </p>
                  </Box>
                  <div className="input-field">{annotationType}</div>
                </div>
              )}

              <p className="details-heading">Account Details</p>
              <AccountsInput
                label={GL_ACCOUNT}
                state={glCheck}
                setState={setGlCheck}
                data={glInfo?.glAccount}
                setPermission={setGlAccount}
                permission={glAccount}
              />
              {isConstructionCompany && (
                <>
                  <AccountsInput
                    label={JOB_ID}
                    state={jobCheck}
                    setState={setJobCheck}
                    data={glInfo?.jobIds}
                    setPermission={setJobId}
                    permission={jobId}
                  />
                  <AccountsInput
                    label={COST_ID}
                    state={costCheck}
                    setState={setCostCheck}
                    data={glInfo?.costIds}
                    setPermission={setCostId}
                    permission={costId}
                  />
                  <AccountsInput
                    label={COST_TYPE}
                    state={costTypeCheck}
                    setState={setCostTypeCheck}
                    data={glInfo?.costType}
                    setPermission={setCostType}
                    permission={costType}
                  />
                </>
              )}
            </>
          )}

          <div className="buttons-bar">
            {!isEmpty(lineItem) && (
              <BottomDrawer
                userOrVendor={userOrVendor}
                isPoMatched={isPoMatched}
                isLineItemsMatched={isLineItemsMatched}
                itemsData={arrangedLineitems(lineItem)}
                handleClick={handleClick}
                setRef={setRef}
                setLineItemsData={setLineItemsData}
                endIcon={
                  <TaskIcon sx={{ width: { xs: "13px", lg: "17px" } }} />
                }
                disabled={
                  po === "" ||
                  po === "-" ||
                  totalAmount === "" ||
                  totalAmount === "-"
                }
                onClick={checkPoMatching}
              />
            )}
            <Grid
              container
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  color: Colors.TEXTGREY,
                  alignItems: "center",
                  fontSize: "17px",
                }}
              >
                <Checkbox
                  sx={{
                    color: "#1AACAC !important",
                    paddingLeft: "0px !important",
                  }}
                  indeterminate={
                    (buttonColors?.length > 0 &&
                      buttonColors?.length < content?.length) ||
                    typeCheck === true ||
                    glCheck === true ||
                    jobCheck === true ||
                    costCheck === true ||
                    costTypeCheck === true
                  }
                  indeterminateIcon={
                    buttonColors?.length === content?.length &&
                    typeCheck &&
                    glCheck &&
                    jobCheck &&
                    costTypeCheck &&
                    costCheck ? (
                      <CheckBox />
                    ) : isEmpty(buttonColors) &&
                      glCheck === false &&
                      jobCheck === false &&
                      costCheck === false &&
                      costTypeCheck === false &&
                      typeCheck === false ? (
                      <CheckBoxOutlineBlank />
                    ) : !isEmpty(buttonColors) &&
                      !buttonColors.includes(true) ? (
                      <CheckBoxOutlineBlank />
                    ) : (
                      <IndeterminateCheckBox />
                    )
                  }
                  checked={buttonColors?.length === content?.length}
                  onChange={handleParentCheckboxChange}
                />
                {VERIFY}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: ".5rem 0rem",
                  justifyContent: "space-between",
                }}
              >
                {userDetails?.user?.poMatchPermission &&
                  userOrVendor ===
                    "app"(
                      <Button
                        sx={{
                          marginBottom: "10px",
                          borderRadius: "5px",
                          color: Colors.DARKBLUE,
                          fontWeight: "bold",
                          fontSize: { xs: "10px", lg: "15px" },
                          "&:hover": {
                            background: "none",
                          },
                          "&:disabled": {
                            color: "gray",
                            cursor: "not-allowed",
                          },
                        }}
                        startIcon={
                          <TaskIcon
                            sx={{ width: { xs: "13px", lg: "20px" } }}
                          />
                        }
                        disabled={
                          po === "" ||
                          po === "-" ||
                          totalAmount === "" ||
                          totalAmount === "-"
                        }
                        onClick={checkPoMatching}
                      >
                        {MATCH_PO}
                      </Button>
                    )}
              </div>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              disabled={
                isTextNotChecked ||
                ((typeCheck === false ||
                  glCheck === false ||
                  jobCheck === false ||
                  costTypeCheck === false ||
                  costCheck === false) &&
                  userOrVendor === "app")
              }
              sx={{
                marginBottom: "7px",
                borderRadius: "10px",
                fontWeight: "bold",
                height: "2.2rem",
                position: "relative",

                "&:disabled": {
                  color: "gray",
                  cursor: "not-allowed",
                },
              }}
            >
              <div
                style={{ width: "80%" }}
                onClick={(e) => {
                  if (userOrVendor === "app") {
                    submitData(e, "Approved");
                  } else {
                    updateVendorData(e, "Approved");
                  }
                }}
              >
                {submitLoading ? (
                  <CircularProgress
                    size={18}
                    sx={{ color: Colors.WHITE, marginTop: "6px" }}
                  />
                ) : (
                  APPROVE
                )}
              </div>
              {(userRole === ACCOUNTANT || userRole === OWNER) &&
                userOrVendor === "app" && (
                  <MuiModal
                    text="Approve"
                    show="manualUsers"
                    setMails={setSelectedEmails}
                    mails={selectedEmails}
                    apiData={manualEmails}
                    marginBottom="1rem"
                    variant="contained"
                    btnHeight="2.5rem"
                    submitData={submitData}
                  />
                )}
            </Button>
          </div>
        </Grid>
      )}
    </div>
  );
}

export default Reviewbar;
