/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import useDynamicRefs from "use-dynamic-refs";

import { CircularProgress, Container, Grid, Hidden, Box } from "@mui/material";

import { Colors } from "../config/default";
import {
  getDocumentDetails,
  matchPO,
  updateDocument,
  uploadCsv,
  sendDataToCallBackApi,
} from "../services/services";
import { userRoles } from "../constants/appConstants";
import {
  formatLineItems,
  arrangedLineitems,
  buttonArray,
  filterDocuments,
} from "../services/common";
import {
  ValidateAnnotation,
  validateG702,
  ValidateGlAccount,
} from "../services/validationService";
import Layout from "../components/layout";
import { useToast } from "../toast/toastContext";
import "../image.css";
import Reviewbar from "../components/reviewbar";
import LoadNextAndPrevDoc from "../components/loadNextAndPrevDoc";
import { get_pending_data } from "../redux/actions/action";
import { isEmpty } from "lodash";

import { getPosition, drawLine } from "../services/common";

import _debounce from "lodash/debounce";

let scrollStopTimer;
export default function CanvasPage() {
  const dispatch = useDispatch();
  const url = window.location.href;
  const urlParams = new URL(url);
  const { id } = useParams();
  const token = urlParams.searchParams.get("token");
  const userOrVendor = urlParams.searchParams.get("callSource")?.toLowerCase();
  const redirectUrl = urlParams.searchParams.get("callBackUrl");
  const blockUser = true;
  localStorage.setItem("token", token);
  const { ACCOUNTANT, OWNER, CLEVEL, MANAGER } = userRoles;
  const [currentId, setCurrentId] = useState(id);
  const queue = useSelector((state) => state?.pending?.pending[0]);
  const [documents, setDocuments] = useState([]);
  let currentIndex;
  if (userOrVendor === "app" && !blockUser) {
    const currentDocumentId = id;
    setDocuments(filterDocuments(queue));
    currentIndex = documents?.findIndex((doc) => doc._id === currentDocumentId);
  }
  const [imageDimensions, setImageDimensions] = React.useState({});
  const imgElement = React.useRef(null);
  const [actualValues, setActualValues] = React.useState([]);
  const [valueNames, setValueNames] = React.useState([]);
  const [content, setContent] = React.useState([]);
  const [lineItem, setLineItem] = React.useState([]);
  const [getRef, setRef] = useDynamicRefs();
  const [canvasDimensions, setCanvasDimensions] = useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [images, setImages] = useState([]);
  const [annotationType, setAnnotationType] = useState("");
  const [index, setIndex] = useState(0);
  const [indexLength, setIndexLength] = useState(0);
  const array = Array(indexLength).fill(false);
  const [buttonColors, setButtonColors] = useState(array);
  const [isConstructionCompany, setIsConstructionCompany] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const [isPoMatched, setIsPoMatched] = useState(true);
  const [isAmountMatched, setIsAmountMatched] = useState(true);
  const [po, setPo] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [permission, setPermission] = useState("");
  const [lineItemsData, setLineItemsData] = useState("");
  const [jobId, setJobId] = useState("");
  const [costId, setCostId] = useState("");
  const [glAccount, setGlAccount] = useState("");
  const [costType, setCostType] = useState("");
  const [vendors, setVendors] = useState("");
  const [glInfo, setGlInfo] = useState({});
  const [vendorsData, setVendorsData] = useState({});
  const [isLineItemsMatched, setIsLineItemsMatched] = useState([]);
  const [manualEmails, setManualEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [comment, setComment] = useState([]);
  const [docName, setDocName] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [windowScroll, setWindowScroll] = useState(null);
  const [reviewBarScroll, setReviewBarScroll] = useState(null);

  const { showToast } = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    if (userOrVendor !== "api" && blockUser) {
      navigate("/unauthorized");
    }
  }, [userOrVendor]);
  const user = useSelector(
    (state) => state?.userInfo?.userInfo?.user?.userName
  );
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const handleImageLoad = (imageRef) => {
    // eslint-disable-next-line no-unused-vars
    const { innerWidth: width, innerHeight: height } = window;
    let scrollHeight = Math.max(
      innerHeight - 73,
      imageRef.height + 64 // compensate for top navbar
    );
    const dimensions = {
      width: imageRef.width,
      height: imageRef.height,
    };
    const canvasSize = {
      width: innerWidth - 15, //compensate for scroll bar
      height: scrollHeight, // compensate for top navbar
    };
    setImageDimensions(dimensions);
    setCanvasDimensions(canvasSize);
  };
  const fetchDocumentData = async (id, userOrVendor) => {
    setIsLoading(true);
    const res = await getDocumentDetails(id, userOrVendor);

    if (
      res?.status === 200 &&
      !isEmpty(res?.data?.payload?.data) &&
      res?.data?.payload?.data?.documentStatus !== "Duplicate"
    ) {
      const data = res?.data?.payload?.data;
      setLineItem(data?.document?.lineItems);
      let content = data?.document?.standardContent;
      content?.sort((a, b) =>
        a.name === "from_contractor" && b.name !== "from_contractor"
          ? -1
          : a.name !== "from_contractor" && b.name === "from_contractor"
          ? 1
          : 0
      );
      setVendors(
        data?.document?.matchVendor === "N/A"
          ? content[0]?.value
          : data?.document?.matchVendor
      );
      content?.forEach((item) => {
        item.isEnable = "false";
      });

      const filteredUsers = data?.companyUser?.filter(
        (item) => item?.role !== "Accountant" && item?.signUp === true
      );
      const filteredmails = filteredUsers?.map((item) => item?.email);
      setManualEmails(filteredmails);
      setVendorsData(data);
      setIndexLength(content?.length);
      setImages(data?.document?.convertedS3Keys);
      setIsConstructionCompany(data?.document?.constructionCompany);
      setUserEmail(data?.document?.email);
      if (userOrVendor === "app" && !blockUser) {
        setAnnotationType(data?.document?.documentType);
        setCostId(data?.document?.userCostId);
        setJobId(data?.document?.userJobId);
        setGlAccount(data?.document?.GlAccount);
        setCostType(data?.document?.userCostType);
        setGlInfo(data?.glInformation);
        setPermission(
          data?.document?.documentType === "Invoice"
            ? "Yes"
            : data?.document?.documentType === "G702"
            ? "No"
            : "Receipt"
        );
      }

      const valuesArray = [];
      const namesArray = [];
      content?.forEach((element) => {
        valuesArray.push(
          element.name === "total"
            ? parseFloat(element.value).toFixed(2)
            : element.value
        );
        namesArray.push(element.name);
      });
      content?.forEach((val) => {
        if (val?.name === "purchase_order") setPo(val?.value);
        if (val?.name === "total") setTotalAmount(val?.value);
      });
      setActualValues(valuesArray);
      setValueNames(namesArray);
      setContent(content);
      setDocName(data?.document?.originalFileName);
      setIsLoading(false);
    } else if (
      res?.status === 200 &&
      res?.data?.payload?.data?.documentStatus === "Duplicate"
    ) {
      navigate("/duplicateDocumentPage");
    } else if (res?.status === 200 && isEmpty(res?.data?.payload?.data)) {
      navigate("/unauthorized");
    } else {
      navigate("/unauthorized");
    }
  };
  const lineItemsFormattedArray = formatLineItems(lineItem);
  const fetchPoResult = async (val, amount) => {
    const params = {
      poNumber: val,
      total: totalAmount,
      lineItems: lineItemsFormattedArray,
    };
    const result = await matchPO(id, params);
    setIsPoMatched(result?.data?.payload?.data?.poNumber);
    setIsAmountMatched(result?.data?.payload?.data?.total);
    setIsLineItemsMatched(result?.data?.payload?.data?.lineItem);
  };
  const hasFetchPoResultRun = useRef(false);
  useEffect(() => {
    if (
      !hasFetchPoResultRun.current &&
      po &&
      totalAmount &&
      userOrVendor === "app" &&
      !blockUser
    ) {
      fetchPoResult(po, totalAmount);
      hasFetchPoResultRun.current = true;
    }
  }, [po && totalAmount]);
  useEffect(() => {
    fetchDocumentData(id, userOrVendor);
  }, [id, userOrVendor]);

  const submitData = async (event, docStatus) => {
    event.preventDefault();
    let updatedData = [...content];
    actualValues.forEach((element, idx) => {
      updatedData[idx].value = element.replace(/[$%]/g, "");
    });
    if (
      (content[0]?.name === "vendor" ||
        content[0]?.name === "from_contractor") &&
      vendorsData?.document?.matchVendor !== "N/A"
    ) {
      const vendorObject = updatedData?.find((data) => data.name === "vendor");
      const contractorObject = updatedData.find(
        (data) => data.name === "from_contractor"
      );
      if (vendorObject) {
        vendorObject.value = vendors;
      } else if (contractorObject) {
        contractorObject.value = vendors;
      }
    }
    let lineItemsCopy = [...lineItem];
    Object.keys(lineItemsData)?.forEach((key) => {
      lineItemsData[key]?.forEach((val, index) => {
        const check = lineItemsCopy[index]?.findIndex(
          (item) => item?.name === key
        );
        if (check !== -1) {
          lineItemsCopy[index][check].value = val === "N/A" ? "" : val;
        }
      });
    });

    const uploadCsvIfNeeded = async (array, type) => {
      const exists = glInfo?.[type]?.indexOf(array[0].name) !== -1;
      if (!exists && array[0].name !== "") {
        const csvArray = {
          email: userEmail,
          glInfo: type,
          dataArray: array,
          newGlList: false,
        };
        await uploadCsv(csvArray);
      }
    };
    const uploadVendorIfNeeded = async () => {
      const exists = glInfo?.vendor?.some((vendor) => vendor?.name === vendors);
      if (!exists) {
        const vendorArray = {
          email: userEmail,
          glInfo: "vendors",
          dataArray: [{ name: vendors, email: "" }],
          newGlList: false,
        };
        await uploadCsv(vendorArray);
      }
    };
    const type = annotationType;
    const validationResponse =
      type === "G702"
        ? validateG702(updatedData)
        : ValidateAnnotation(updatedData);
    const glAccountCheck = ValidateGlAccount(glAccount);
    if (validationResponse.status === true && glAccountCheck.status === true) {
      const newData = {
        modifier: user,
        content: updatedData,
        documentType:
          permission === "Yes"
            ? "Invoice"
            : permission === "No"
            ? "G702"
            : "Receipt",
        userJobId: jobId || "",
        userCostId: costId || "",
        GlAccount: glAccount || "",
        userCostType: costType || "",
        lineItems: lineItemsCopy,
        matchVendor: "N/A",
        manualUsers: selectedEmails,
        comment: comment,
        status: docStatus,
        callSource: userOrVendor,
      };

      // Upload CSVs if needed
      await uploadCsvIfNeeded([{ name: glAccount }], "accounts");
      if (isConstructionCompany) {
        await uploadCsvIfNeeded([{ name: jobId }], "jobIds");
        await uploadCsvIfNeeded([{ name: costId }], "costIds");
        await uploadCsvIfNeeded([{ name: costType }], "costType");
      }
      if (
        content[0]?.name === "vendor" ||
        content[0]?.name === "from_contractor"
      ) {
        await uploadVendorIfNeeded();
      }
      const updateResponse = await updateDocument(id, newData);
      if (updateResponse?.data?.metadata?.status === "SUCCESS") {
        showToast(updateResponse?.data?.metadata?.message, "success");
        setIsLoading(true);
        const prevId = currentId;
        const updatedDocuments = documents?.filter((doc) => doc._id !== prevId);
        dispatch(get_pending_data(updatedDocuments));
        if (!isEmpty(redirectUrl)) {
          await sendDataToCallBackApi(
            redirectUrl,
            updateResponse?.data?.payload?.data
          );
        }
        if (
          documents &&
          documents.length > 0 &&
          currentIndex !== documents?.length - 1 &&
          userOrVendor === "app"
        ) {
          const nextIndex = (currentIndex + 1) % documents.length;
          const nextDocumentId = documents[nextIndex]._id;
          setImageDimensions({});
          setImages([]);
          setButtonColors([]);
          // setTypeCheck(false);
          // setGlCheck(false);
          // setCostTypeCheck(false);
          // setJobCheck(false);
          // setCostCheck(false);
          setCurrentId(nextDocumentId);
          navigate(`/canvas/${nextDocumentId}`);
          fetchDocumentData(nextDocumentId, userOrVendor);
        } else if (userRole === CLEVEL || userRole === MANAGER) {
          navigate("/approver");
        } else if (userRole === ACCOUNTANT) {
          navigate("/accountant");
        } else {
          navigate("/home");
        }
      } else {
        showToast(
          updateResponse?.response?.data?.metadata?.message ||
            updateResponse?.data?.metadata?.message,
          "error"
        );
      }
    } else {
      showToast(validationResponse.message || glAccountCheck.message, "error");
    }
  };
  const updateVendorData = async (event, docStatus) => {
    event.preventDefault();
    setSubmitLoading(true);
    let updatedData = [...content];
    actualValues?.forEach((element, idx) => {
      updatedData[idx].value = element.replace(/[$%]/g, "");
    });
    let lineItemsCopy = [...lineItem];
    Object.keys(lineItemsData)?.forEach((key) => {
      lineItemsData[key]?.forEach((val, index) => {
        const check = lineItemsCopy[index]?.findIndex(
          (item) => item?.name === key
        );
        if (check !== -1) {
          lineItemsCopy[index][check].value = val === "N/A" ? "" : val;
        }
      });
    });

    const newData = {
      modifier: "Vendor",
      content: updatedData,
      documentType:
        permission === "Yes"
          ? "Invoice"
          : permission === "No"
          ? "G702"
          : "Receipt",
      userJobId: jobId || "",
      userCostId: costId || "",
      GlAccount: glAccount || "",
      userCostType: costType || "",
      lineItems: lineItemsCopy,
      matchVendor: "N/A",
      manualUsers: selectedEmails,
      comment: comment,
      status: docStatus,
      callSource: userOrVendor,
    };
    const updateResponse = await updateDocument(id, newData);
    if (updateResponse?.data?.metadata?.status === "SUCCESS") {
      if (!isEmpty(redirectUrl)) {
        await sendDataToCallBackApi(
          redirectUrl,
          updateResponse?.data?.payload?.data
        );
      }
      showToast(updateResponse?.data?.metadata?.message, "success");
    } else {
      showToast(
        updateResponse?.response?.data?.metadata?.message ||
          updateResponse?.data?.metadata?.message,
        "error"
      );
    }
    setSubmitLoading(false);
  };

  const handleClick = (referenceId, idx, dataArray) => {
    let buttonPosition = getPosition("button_" + referenceId, getRef);
    let boxPosition = getPosition("box_" + referenceId, getRef);

    if (
      buttonPosition &&
      boxPosition?.width !== 0 &&
      boxPosition?.height !== 0
    ) {
      drawLine(buttonPosition, boxPosition, idx, dataArray, imageDimensions);

      // This code is handle draw line with scroll need some improvements please dont remove this part
      // const targetScrollPosition =
      //   boxPosition.top +
      //   window.scrollY -
      //   window.innerHeight +
      //   boxPosition.height;
      // window.scrollTo({ top: targetScrollPosition, behavior: "smooth" });
      // This code is handle draw line with scroll need some improvements please dont remove this part
    } else {
      drawLine("", "", idx, dataArray, imageDimensions);
    }

    setWindowScroll({ referenceId, idx, dataArray });
    setReviewBarScroll({ referenceId, idx, dataArray });
  };

  const handleScroll = () => {
    if (reviewBarScroll) {
      handleClick(
        reviewBarScroll?.referenceId,
        reviewBarScroll?.idx,
        reviewBarScroll?.dataArray
      );
    }
  };

  const handleWindowScroll = _debounce(() => {
    if (windowScroll) {
      const { referenceId, idx, dataArray } = windowScroll;
      drawLine("", "", idx, dataArray, imageDimensions);
      clearTimeout(scrollStopTimer);
      scrollStopTimer = setTimeout(() => {
        handleClick(referenceId, idx, dataArray);
      }, 1000);
    }
  }, 10);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, [windowScroll]);

  useEffect(() => {
    const reviewBar = document.getElementById("review-bar");
    if (reviewBar) {
      reviewBar.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (reviewBar) {
        reviewBar.removeEventListener("scroll", handleScroll);
      }
    };
  }, [reviewBarScroll]);

  return (
    <Grid
      conatiner
      sx={{
        height: "100vh",
        backgroundColor: Colors.BG_BLACK,
      }}
    >
      <Layout
        buttonArray={buttonArray}
        path="canvas"
        show={userDetails?.role === OWNER ? "showUser" : ""}
        submitData={submitData}
        id={id}
        comment={comment}
        setComment={setComment}
        docName={docName}
        padding={true}
        userOrVendor={userOrVendor}
      />

      <Container
        maxWidth={null}
        style={{
          padding: 0,
        }}
      >
        <>
          <canvas
            id="myCanvas"
            height={canvasDimensions?.height || "100vh"}
            width={canvasDimensions?.width}
            style={{
              position: "absolute",
              zIndex: 99,
            }}
          ></canvas>
          <div className="main-container">
            <Reviewbar
              id={id}
              isLoading={isLoading}
              content={content}
              vendors={vendors}
              images={images}
              lineItem={lineItem}
              annotationType={annotationType}
              userDetails={userDetails}
              isConstructionCompany={isConstructionCompany}
              setSelectedEmails={setSelectedEmails}
              selectedEmails={selectedEmails}
              costId={costId}
              setCostId={setCostId}
              jobId={jobId}
              setJobId={setJobId}
              glAccount={glAccount}
              costType={costType}
              setCostType={setCostType}
              glInfo={glInfo}
              setGlAccount={setGlAccount}
              permission={permission}
              actualValues={actualValues}
              isLineItemsMatched={isLineItemsMatched}
              manualEmails={manualEmails}
              setPermission={setPermission}
              isPoMatched={isPoMatched}
              isAmountMatched={isAmountMatched}
              imageDimensions={imageDimensions}
              setIsAmountMatched={setIsAmountMatched}
              valueNames={valueNames}
              setIsPoMatched={setIsPoMatched}
              lineItemsData={lineItemsData}
              setLineItemsData={setLineItemsData}
              buttonColors={buttonColors}
              array={array}
              setIndex={setIndex}
              index={index}
              setButtonColors={setButtonColors}
              setIsLineItemsMatched={setIsLineItemsMatched}
              po={po}
              totalAmount={totalAmount}
              getRef={getRef}
              setRef={setRef}
              setActualValues={setActualValues}
              vendorsData={vendorsData}
              setVendors={setVendors}
              setPo={setPo}
              setTotalAmount={setTotalAmount}
              submitData={submitData}
              userRole={userRole}
              userOrVendor={userOrVendor}
              updateVendorData={updateVendorData}
              submitLoading={submitLoading}
              handleClick={handleClick}
            />
            <Hidden smDown>
              <div
                className="right-area"
                id="right-area"
                style={{
                  backgroundColor: Colors.BG_BLACK,
                  // height: canvasDimensions.height,
                }}
              >
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      height: "80vh",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      size={75}
                      sx={{ color: Colors.DARKBLUE }}
                    />
                  </div>
                ) : (
                  <>
                    {userOrVendor === "app" && (
                      <LoadNextAndPrevDoc
                        id={id}
                        setCurrentId={setCurrentId}
                        setImageDimensions={setImageDimensions}
                        setButtonColors={setButtonColors}
                        // setTypeCheck={setTypeCheck}
                        // setGlCheck={setGlCheck}
                        // setCostTypeCheck={setCostTypeCheck}
                        // setJobCheck={setJobCheck}
                        // setCostCheck={setCostCheck}
                        setImages={setImages}
                      />
                    )}

                    <div
                      className="box-paper"
                      style={{ padding: "1.5rem 1.5rem 0rem 1.5rem" }}
                    >
                      {images?.length > 0 && (
                        <>
                          <img
                            id="i"
                            src={images[index]}
                            alt={index}
                            ref={imgElement}
                            className="image"
                            onLoad={() => handleImageLoad(imgElement.current)}
                            style={{
                              objectFit: "fit",
                            }}
                          />
                        </>
                      )}
                      {content.map((value, idx) =>
                        value?.page === index || value?.page === null ? (
                          <Box
                            onMouseEnter={() =>
                              handleClick(value.name, idx, content)
                            }
                            // onClick={() => {
                            //   handleClick(value.name, idx, content);
                            // }}
                            // onMouseLeave={() => handleClick("", idx, content)}
                            ref={setRef("box_" + value.name)}
                            key={idx}
                            style={{
                              zIndex: "999",
                              height: value.height * imageDimensions.height,
                              background: "#00FF3C",
                              width: value.width * imageDimensions.width,
                              position: "absolute",
                              top: value.top * imageDimensions.height + 24,
                              left: value.left * imageDimensions.width + 24,
                              opacity: 0.4,
                            }}
                          ></Box>
                        ) : (
                          ""
                        )
                      )}

                      {arrangedLineitems(lineItem)?.map(
                        (element, parentIndex) => {
                          return element?.map((items, idx) => {
                            return items?.page === index ||
                              items?.page === null ||
                              items?.page === "-" ||
                              items?.page === "" ? (
                              <Box
                                ref={setRef(
                                  "box_" +
                                    items?.value +
                                    "**%**" +
                                    parentIndex +
                                    idx
                                )}
                                key={idx}
                                style={{
                                  height: items.height * imageDimensions.height,
                                  background: "#00FF3C",
                                  width: items.width * imageDimensions.width,
                                  position: "absolute",
                                  top: items.top * imageDimensions.height + 24,
                                  left: items.left * imageDimensions.width + 24,
                                  opacity: 0.4,
                                }}
                              ></Box>
                            ) : (
                              ""
                            );
                          });
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
            </Hidden>
          </div>
        </>
      </Container>
    </Grid>
  );
}
